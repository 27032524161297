<template>
  <v-layout column wrap>
    <v-card
      class="mx-auto"
    >
      <v-card-text>
        <v-container fluid>
          <v-row dense>
            <v-col
              v-for="card in cards"
              :key="card.title"
              :cols="card.flex"
            >
              <v-card @click="buttonClick(card.title)">
                <v-img
                  :src="card.src"
                  class="align-end"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  height="280px"
                >
                  <p v-text="card.title" class="white--text text-xl ms-2"></p>
                </v-img>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <withdrawal-slips-time-deposit
      v-on:dialog_show="on_add_time_withdrawal_dialog_close"
      :dialog_show="add_dialog_time_withdrawal"
    />
    <withdrawal-slips-share-capital
      v-on:dialog_show="on_add_share_withdrawal_dialog_close"
      :dialog_show="add_dialog_share_withdrawal"
    />
    <withdrawal-slips-loans-savings
      v-on:dialog_show="on_add_loan_savings_withdrawal_dialog_close"
      :dialog_show="add_dialog_loan_savings_withdrawal"
    />
  </v-layout>
</template>

<script>
  import WithdrawalSlipsTimeDeposit
    from './withdrawals_interface/NewApprovalWithdrawalSlipsTimeDeposit'
  import WithdrawalSlipsShareCapital
    from './withdrawals_interface/NewApprovalWithdrawalSlipsShareCapital'
  import WithdrawalSlipsLoansSavings
    from './withdrawals_interface/NewApprovalWithdrawalSlipsLoanSavings'

  export default {
    components: {
      WithdrawalSlipsTimeDeposit,
      WithdrawalSlipsShareCapital,
      WithdrawalSlipsLoansSavings,
    },
    data: () => ({
      add_dialog_time_withdrawal: false,
      add_dialog_share_withdrawal: false,
      add_dialog_loan_savings_withdrawal: false,
      cards: [
        {
          title: 'Time Deposit Withdrawal',
          src: require('@/assets/images/misc/time-deposit.png'),
          flex: 6
        },
        {
          title: 'Loan Savings Withdrawal',
          src: require('@/assets/images/misc/loan-savings.png'),
          flex: 6
        },
        {
          title: 'Share Capital Withdrawal',
          src: require('@/assets/images/misc/share-capital.png'),
          flex: 6
        },
      ],
    }),
    props: {
      dialog_show: Boolean,
    },
    methods: {
      buttonClick(value) {
        switch (value) {
          case 'Time Deposit Withdrawal':
            this.add_dialog_time_withdrawal = true
            break
          case 'Loan Savings Withdrawal':
            this.add_dialog_loan_savings_withdrawal = true
            break
          case 'Share Capital Withdrawal':
            this.add_dialog_share_withdrawal = true
            break
        }
      },
      on_add_time_withdrawal_dialog_close(value) {
        this.add_dialog_time_withdrawal = value
      },
      on_add_share_withdrawal_dialog_close(value) {
        this.add_dialog_share_withdrawal = value
      },
      on_add_loan_savings_withdrawal_dialog_close(value) {
        this.add_dialog_loan_savings_withdrawal = value
      },
    }
  }
</script>
