<template>
  <div>
    <v-dialog v-model="withdrawal_share_capital_dialog" persistent width="70%">
      <v-card
        flat
        class="pa-3 mt-2"
      >
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">SHARE CAPITAL WITHDRAWAL</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" class="multi-col-validation mt-6">
            <v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="8"
                  md="6"
                >
                  <v-combobox
                    :append-icon="icons.mdiAccountSearchOutline"
                    v-model="search"
                    @keyup="searching($event.target.value)"
                    @change="get_search_items_info"
                    :items="search_items"
                    item-value="id"
                    item-text="member_name"
                    placeholder="Search by (Account Name/Account #)"
                    ref="memberCombobox"
                    dense
                    outlined
                  ></v-combobox>
                  <v-text-field
                    v-model="cmf_no"
                    label="CMF No"
                    dense
                    outlined
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                    readonly
                  ></v-text-field>
                  <v-text-field
                    v-model="member_name"
                    label="Account Name"
                    dense
                    outlined
                    :rules="rules.default_max_255_character_and_no_empty_rule"
                    readonly
                  ></v-text-field>
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="amount_num"
                        label="Amount to Withdraw"
                        dense
                        outlined
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- alert -->
                  <v-col cols="12" v-show="alert">
                    <v-alert
                      color="warning"
                      text
                      class="mb-0"

                    >
                      <div class="d-flex align-start">
                        <v-icon color="warning">
                          {{ icons.mdiAlertOutline }}
                        </v-icon>

                        <div class="ms-3">
                          <p class="text-base font-weight-medium mb-1">
                            {{alert_message}}
                          </p>
                        </div>
                      </div>
                    </v-alert>
                  </v-col>

                  <v-col cols="12">
                    <v-btn
                      color="primary"
                      class="me-3 mt-4"
                      @click="save_withdrawal_slips"
                      v-if="!saving"
                    >
                      Save changes
                    </v-btn>
                    <v-progress-circular
                      :size=50
                      :width="5"
                      color="primary"
                      indeterminate
                      v-else
                    ></v-progress-circular>
                  </v-col>
                </v-col>

                <v-col
                  cols="12"
                  sm="4"
                  md="6"
                  class="d-none d-sm-flex justify-center position-relative"
                >
                  <carousel :per-page=1>
                    <slide>
                      <img v-bind:src="photo1" alt="" width="100%" height="100%">
                    </slide>
                  </carousel>
                </v-col>
              </v-row>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mx-1" color="error" @click="close_dialog()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  export default {
    props: {
      dialog_show: Boolean,
    },
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
        },
      }
    },
    data() {
      return {
        withdrawal_share_capital_dialog: false,

        photo1: '',
        check_no: '',

        saving: false,
        alert: false,
        alert_message: '',

        search: '',
        search_member: '',
        search_items: [],

        member_id: '',
        cmf_no: '',
        member_name: '',
        amount: 0,
        amount_num: '0',

        status: '',
      }
    },
    watch: {
      dialog_show: function () {
        this.withdrawal_share_capital_dialog = this.dialog_show
      },
      search_member: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member == '') {
            this.search_items = []
          } else {
            this.searching_search_items(this.search_member)
          }
        }, 500)
      },
    },
    beforeDestroy() {
      this.reset()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['branch_id']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
      ...mapGetters('authentication', ['user_id', 'branch', 'branch_id', 'month_start', 'month_end', 'month_of']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('share_capital', ['search_members_have_share_not_yet_released', 'create_approval_withdraw']),
      reset() {
        this.saving = false
        this.alert = false
        this.alert_message = ''

        this.photo1 = ''
        this.check_no = ''

        this.search = ''
        this.search_member = ''
        this.search_items = []

        this.member_id = 0
        this.cmf_no = ''
        this.member_name = ''

        this.amount = 0
      },
      searching(value) {
        this.search_member = value
      },
      searching_search_items(value) {
        this.search_members_have_share_not_yet_released({
          search_word: value
        })
          .then(response => {
            this.search_items = response.data
          })
      },
      get_search_items_info() {
        if (this.search_items.length > 0) {
          var index = this.search_items.map(function (x) {
            return x.id;
          }).indexOf(this.search.id)
          if (index != -1) {
            this.photo1 = this.search_items[index].member_valid_signature
            this.member_id = this.search_items[index].id
            this.cmf_no = this.search_items[index].cmf_no
            this.member_name = this.search_items[index].member_name
            this.amount = this.search_items[index].amount
            this.amount_num = this.search_items[index].amount_num
            this.$refs.form.validate()
          } else {
            this.reset()
          }
        } else {
          this.reset()
        }
      },
      save_withdrawal_slips() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('branch_id', this.branch_id);
          data.append('member_id', this.member_id);
          data.append('withdrawal', this.amount);
          data.append('month_of', this.month_of);
          data.append('created_by', this.user_id);
          this.create_approval_withdraw(data)
            .then(response => {
              if (response.status === 201) {
                this.change_snackbar({
                  show: true,
                  color: 'error',
                  text: response.data,
                })
                this.saving = false
                this.alert = true
                this.alert_message = response.data
              } else {
                // this.print_withdrawal_slip(response.data.id, moment().format('MMMM DD, YYYY'), moment().format('hh:mm:ss'), response.data.member_id)
                this.change_snackbar({
                  show: true,
                  color: response.status===200?'success':'error',
                  text: response.data,
                })
                this.reset()
                this.close_dialog()
              }
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
      print_withdrawal_slip(id, dateeee, time, reg_id) {
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          content: [
            {text: 'ShareCap, Cash Withdrawal', style: 'printed_label'},
            {text: 'REFWITH' + id, style: 'printed_label'},
            {text: this.cmf_no, style: 'printed_label'},
            {text: this.member_name, style: 'printed_label'},
            {text: 'PHP ' + this.amount, style: 'printed_label'},
            {text: 'SHARE CAPITAL WITHDRAWALS', style: 'printed_label'},
            {text: dateeee + ' ' + time, style: 'printed_label'},
            {text: 'RGST' + reg_id, style: 'printed_label'},
            {text: 'Branch: ' + this.branch, style: 'printed_label'},
            {text: 'Withdrewer: ' + this.member_name.toUpperCase(), style: 'printed_label'},
          ],
          styles: {
            printed_label: {
              color: 'blue',
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
          }
        }
        pdfMake.createPdf(docDefinition).open();
      },
      close_dialog() {
        this.reset()
        this.$emit('dialog_show', false)
      },
    }
  }
</script>

<style lang="scss" scoped>
  .two-factor-auth {
    max-width: 25rem;
  }

  .security-character {
    position: absolute;
    bottom: -0.5rem;
  }
</style>
